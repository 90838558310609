/* src/App.css */

body{
    background-color: white;
}
/* 
  button,input {
    position: relative;
    z-index: 10;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    margin-left:10px;
    border-radius: 5px;
    cursor: pointer;
    min-width:150px;
    margin-bottom:50px;
  }
  
  .toolbar {
    overflow:scroll !important;
    position: fixed;
    top: 10px;
    width:100%;
    left: 10px;
    z-index: 10;
    display: flex;
    flex-direction: row;
  }
  
   */